import { axiosInstance } from "../index";

const anonymousSignUp = async () => {
  return axiosInstance.post("auth/anonymous-sign-up", {}).then((response) => {
    if (response.data.token) {
      localStorage.setItem("token", response.data.token);
    }
    return response;
  });
};
const emailOnlySignUp = async (email) => {
  return axiosInstance
    .post("auth/email-only-sign-up", {
      email,
    })
    .then((response) => {
      return response;
    });
};

const emailAndPinSignIn = async (email, pin, setError) => {
  return axiosInstance
    .post("authorization/email-and-pin-sign-in", {
      email,
      pin,
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("FY-AuthToken", response.data.token);
      }
      return response;
    })
    .catch((error) => {
      setError("email", {
        type: "server",
        message: error.response.data.message,
      });
      return error;
    });
};

const sendPinToEmail = async (email, setError) => {
  return axiosInstance
    .post("auth/send-pin-to-email", {
      email,
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("token", response.data.token);
      }
      return response;
    })
    .catch((error) => {
      setError("email", {
        type: "server",
        message: error.response.data.message,
      });
      return error;
    });
};

const logout = () => {
  localStorage.removeItem("FY-AuthToken");
};

const getCurrentUser = () => {
  return localStorage.getItem("FY-AuthToken");
};

const getInfo = async () => {
  return axiosInstance.get("/me").then((response) => {
    return response;
  });
};

const authService = {
  anonymousSignUp,
  sendPinToEmail,
  emailOnlySignUp,
  emailAndPinSignIn,
  logout,
  getCurrentUser,
  getInfo,
};

export default authService;
