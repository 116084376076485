import React, { useState, useEffect } from "react";
import { initializePaddle, Paddle } from "@paddle/paddle-js";
import styles from "./styles.module.scss";
import { PlanItem } from "./components/planItem";
import { useFetchPlans } from "./hooks/useFetchPlans";
import { Button } from "../../../../../common";
import { Scale2 } from "../../../../../assets/Icons";
import { Target } from "../../../../../assets/Icons";
import { useDiscount } from "../../DiscountContext";
import { useNavigate } from "react-router";
import { useGetUserInfo } from "../../../../../hooks/useGetUserProfile";
import { goalMapping } from "./utils/goalMapping";
import { getAgreementText, getPlanPricing } from "./utils/getPlanDetails";

export const Plan = ({ title }) => {
  const [paddle, setPaddle] = useState();

  const navigate = useNavigate();

  const { plans, activePlan, setActivePlan, handleSubmitPlan } =
    useFetchPlans();
  const { isDiscounted } = useDiscount();
  const { user } = useGetUserInfo();

  const agreementText = getAgreementText(activePlan, isDiscounted);

  const ms = localStorage.getItem("ms");
  const goal = goalMapping[user?.questions?.qqGoal] || "Lose weight";
  const weightGoal =
    ms === "us"
      ? user?.questions?.qqGoalWeightLbs
      : user?.questions?.qqGoalWeightKg;

  useEffect(() => {
    initializePaddle({
      environment: "sandbox",
      token: "test_1837b25160438d3257b58b9c9ec",
      eventCallback: function (data) {
        if (data.name == "checkout.completed") {
          setTimeout(() => {
            paddle?.Checkout.close();
            navigate("/auth");
          }, 5000);
        }
      },
    }).then((paddleInstance) => {
      if (paddleInstance) {
        setPaddle(paddleInstance);
      }
    });
  }, [paddle]);

  const handleGetPlan = async () => {
    try {
      const transactionId = await handleSubmitPlan();

      paddle?.Checkout.open({
        transactionId,
      });
    } catch (error) {
      console.error("Error during payment:", error);
    }
  };

  return (
    <div className={styles.content}>
      <div className={styles.choosePlan}>
        <h2>{title}</h2>

        <div className={styles.goals}>
          <div className={styles.goalItem}>
            <div className={styles.icon}>
              <Target />
            </div>
            <div className={styles.text}>
              <p>Goal</p>
              <span>{goal}</span>
            </div>
          </div>
          <div className={styles.divider}></div>
          <div className={styles.goalItem}>
            <div className={styles.icon}>
              <Scale2 />
            </div>
            <div className={styles.text}>
              <p>Weight goal</p>
              <span>
                {weightGoal} {ms === "us" ? "lbs" : "kg"}
              </span>
            </div>
          </div>
        </div>

        <div className={styles.plans}>
          {plans.map((plan) => {
            const {
              title,
              priceNew,
              priceOld,
              perDay,
              perDayOld,
              popular,
              currency,
            } = getPlanPricing(plan);
            return (
              <PlanItem
                key={plan.id}
                onClick={() => setActivePlan(plan)}
                checked={plan.id === activePlan.id}
                title={title}
                priceNew={priceNew}
                priceOld={priceOld}
                perDay={perDay}
                perDayOld={perDayOld}
                popular={popular}
                currency={currency}
                isDiscounted={isDiscounted}
              />
            );
          })}
        </div>
        <div className={styles.agreementContainer}>
          <p className={styles.agreement}>{agreementText}</p>
        </div>
        <div className={styles.control}>
          <Button id="purchase-continue-button" onClick={handleGetPlan}>
            GET MY PLAN
          </Button>
        </div>
      </div>
    </div>
  );
};
