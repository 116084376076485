const getPlanDuration = (duration) => {
  switch (duration) {
    case 7:
      return "1-week";
    case 28:
      return "4-week";
    case 84:
      return "12-week";
    default:
      return `${duration}-days`;
  }
};

export const getAgreementText = (plan, isDiscounted) => {
  const { priceNew, priceOld, duration } = getPlanPricing(plan);
  const durationText = getPlanDuration(duration);

  return isDiscounted ? (
    <>
      By clicking GET MY PLAN, I agree to pay <b>${priceNew}</b> for my plan and
      that if I do not cancel before the end of the <b>{durationText}</b>{" "}
      introductory plan, it will convert to a <b>{durationText}</b> subscription
      and EasyFit will automatically charge my payment method the regular price{" "}
      <b>${priceOld}</b> <b> every {durationText}</b> thereafter until I cancel.
      I can cancel online by visiting subscription page in my account on website
      to avoid being charged for the next billing cycle.
    </>
  ) : (
    <>
      By clicking GET MY PLAN, I agree that EasyFit will automatically charge my
      payment method the regular price <b>${priceOld}</b>
      <b> every {durationText}</b> thereafter until I cancel. I can cancel
      online by visiting subscription page in my account on website to avoid
      being charged for the next billing cycle.
    </>
  );
};

export const getPlanPricing = (plan) => {
  return {
    title: plan?.title,
    popular: plan?.uiDisplayOptions?.title,
    priceNew: (plan?.priceAmount / 100).toFixed(2),
    priceOld: (plan?.noDiscountPriceAmount / 100).toFixed(2),
    perDay: (plan?.dailyPriceAmount / 100).toFixed(2),
    perDayOld: (plan?.noDiscountDailyPriceAmount / 100).toFixed(2),
    currency: plan?.currency || "USD",
    duration: plan?.billingPeriodDuration,
  };
};
