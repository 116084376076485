import { createContext, useContext, useState, useEffect } from "react";

const DiscountContext = createContext();

export const useDiscount = () => useContext(DiscountContext);

export const DiscountProvider = ({ children }) => {
  const [isDiscounted, setIsDiscounted] = useState(true);

  const value = {
    isDiscounted,
    setIsDiscounted,
  };

  return (
    <DiscountContext.Provider value={value}>
      {children}
    </DiscountContext.Provider>
  );
};
