import { useEffect, useState } from "react";
import { axiosInstance } from "../../../../../../api";

export const useFetchPlans = () => {
  const [plans, setPlans] = useState([]);
  const [activePlan, setActivePlan] = useState("");

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const res = await axiosInstance.get("/subscription-plans");
        const { items } = res.data;

        setPlans(items);
        const initialActivePlanIndex = items.findIndex(
          (plan) => plan?.uiDisplayOptions.title
        );
        const initialActivePlan =
          initialActivePlanIndex !== -1
            ? items[initialActivePlanIndex]
            : items[0];
        setActivePlan(initialActivePlan);
      } catch (error) {
        console.log(error);
      }
    };

    fetchPlans();
  }, []);

  const handleSubmitPlan = async () => {
    try {
      const response = await axiosInstance.post(
        `/me/subscriptions?subscriptionPlanId=${activePlan.id}`
      );

      const { paddleTransactionId } = response.data;
      return paddleTransactionId;
    } catch (error) {
      console.error("Error creating Paddle transaction:", error);
      throw error;
    }
  };

  return {
    plans,
    activePlan,
    setActivePlan,
    handleSubmitPlan,
  };
};
