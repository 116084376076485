import React, { useRef } from "react";
import styles from "./styles.module.scss";
import { Transform } from "./components/transform";
import { ResultsSlider } from "./components/results";
import { FAQSection } from "./components/faq";
import { Reviews } from "./components/reviews";
import { Plan } from "./components/plan";
import { Timer } from "./components/timer";
import { Benefits } from "./components/benefits";
import { Partners } from "./components/partners";
import { Guarantee } from "./components/guarantee";
import { DiscountProvider } from "./DiscountContext";

export const Paywall = () => {
  const topPlanRef = useRef(null);
  const bottomPlanRef = useRef(null);
  const faqSectionRef = useRef(null);

  const handleGetMyPlanClick = () => {
    if (faqSectionRef.current) {
      const faqSectionTop =
        faqSectionRef.current.getBoundingClientRect().top + window.scrollY;
      const windowScrollY = window.scrollY + window.innerHeight;

      if (windowScrollY < faqSectionTop) {
        topPlanRef.current.scrollIntoView({ behavior: "smooth" });
      } else {
        bottomPlanRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return (
    <DiscountProvider>
      <div className={styles.layout}>
        <Timer onGetMyPlanClick={handleGetMyPlanClick} />
        <Transform />
        <div ref={topPlanRef}>
          <Plan title="Don’t miss your chance" />
        </div>
        <Benefits />
        <Partners />
        <ResultsSlider />
        <div ref={faqSectionRef}>
          <FAQSection />
        </div>
        <Reviews />
        <div ref={bottomPlanRef}>
          <Plan title="Achieve noticeable results in 4 weeks!" />
        </div>
        <Guarantee />
        <footer className={styles.footer}>
          Straiton Limited | Alpha Tower, Floor 1, Office 11, <br /> Pavlou
          Nirvana- Aipeias, Limassol, 3021, Cyprus
        </footer>
      </div>
    </DiscountProvider>
  );
};
